import React from "react";
require("/src/assets/css/custom.css");
require("/src/assets/css/bootstrap.css");
require("/src/assets/css/style.css");
require("/src/assets/css/responsive.css");
require("/src/assets/css/font-icons.css");

export function wrapPageElement({ element, props }) {
  return (
    <div
      {...props}
      id="main-container"
      className="font-inter text-sm font-medium"
    >
      {element}
    </div>
  );
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.pathname !== (prevLocation && prevLocation.pathname)) {
    window.scrollTo(0, 0);
  }
};
